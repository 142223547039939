import { useAuth } from '@formbio/auth';
import useOnBoarding from '@/hooks/useOnboarding';
import { LinearProgress, styled } from '@formbio/ui';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { getHref, navRoutes } from './routes';
import useUrlParams, { QueryParams } from '@formbio/utils';
import { setUser } from '@sentry/nextjs';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useAnalytics } from './analytics';

const StyledDiv = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
}));

export default function withRequiredAuth<T extends object>(
  WrappedComponent: React.ComponentType<T>,
) {
  return function WithRequiredAuth(props: T) {
    const router = useRouter();
    const { orgId, pid } = useUrlParams();
    const ldClient = useLDClient();
    const { user, loading: isUserLoading } = useAuth();
    const { isPending, isOnboarding } = useOnBoarding();
    const analytics = useAnalytics();

    useEffect(() => {
      if (!isUserLoading && !user) {
        router.push({
          pathname: navRoutes.login.pathname,
          query: { [QueryParams.destination]: router.asPath },
        });
      }
      // TODO: uncomment if/when email verification is enabled
      // Decided as part of the ISA implementation we didn't need to verify emails
      // // user's email isn't verified, send them back to check-verify-email
      // if (user && !user.emailVerified) {
      //   router.push({
      //     pathname: navRoutes.verifyEmail.pathname,
      //   });
      // }
    }, [isUserLoading, user, router.asPath]);

    useEffect(() => {
      if (user) {
        const { uid, email, displayName } = user;

        analytics.identify(uid, {
          email,
          name: displayName,
        });
        setUser({ id: uid, email: email || '' });
      }
      setUser(null);
    }, [user]);

    useEffect(() => {
      if (user && ldClient) {
        const { uid, email, displayName } = user;

        ldClient.identify({
          kind: 'multi',
          user: {
            key: uid,
            name: displayName || undefined, // LaunchDarkly wants a string or undefined, not a null
            email: email || undefined,
          },
          ...(orgId && {
            organization: {
              key: orgId,
            },
          }),
          ...(pid && {
            project: {
              key: pid,
            },
          }),
        });
      }
    }, [ldClient, user, orgId, pid]);

    /*
     * Check if user has an account id but not org or project yet.
     * If so, redirect to onboarding page
     */
    useEffect(() => {
      if (!isPending && isOnboarding) {
        router.push(getHref(router, navRoutes.onboarding));
      }
    }, [isOnboarding, isPending]);

    if (!user || (!isPending && isOnboarding)) {
      return (
        <StyledDiv>
          <LinearProgress />
        </StyledDiv>
      );
    }
    return <WrappedComponent {...props} />;
  };
}
