import { navRoutes } from '@/utils/routes';
import {
  useProjects,
  useAllOrganizations,
  useAccountQuotas,
} from '@formbio/api';
import { useRouter } from 'next/router';
import { useAuth } from '@formbio/auth';
import sortBy from 'lodash/sortBy';

/**
 * This hook determines if a user should be redirected to the onboarding page.
 * We need to check if the user has an account id, an org or a project.
 *
 */
export default function useOnBoarding() {
  const router = useRouter();
  const { user, loading: isUserLoading } = useAuth();
  const { data: projects, isPending: isProjectsPending } = useProjects({
    enabled: !!user,
  });
  const { allOrganizations, isLoading: isAllOrganizationsLoading } =
    useAllOrganizations({ enabled: !!user }, { enabled: !!user });
  const { data: quotas, isPending: isTiersPending } = useAccountQuotas(
    allOrganizations?.[0]?.id,
  );
  const isPending =
    isUserLoading ||
    isAllOrganizationsLoading ||
    isProjectsPending ||
    (isTiersPending && !!allOrganizations?.length);

  const filteredProjects =
    allOrganizations?.[0] &&
    sortBy(projects, proj => proj.name.toLowerCase()).filter(
      it => it.organization.id === allOrganizations[0].id,
    );

  return {
    isPending: isPending,
    isOnboarding:
      quotas?.accountId &&
      (!allOrganizations?.length || !filteredProjects?.length) &&
      router.pathname !== navRoutes.onboarding.pathname,
    allOrganizations,
    projects,
  };
}
