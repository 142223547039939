import AppAvatarMenu from '@/components/avatars/AppAvatarMenu';
import { useNavigationContext } from '@/context/navigationContext';
import { useOrgProjectLocalStorage } from '@/hooks/useOrgProjectLocalStorage';
import bubbles from '@/images/bubbles.jpg';
import { getHref, navRoutes } from '@/utils/routes';
import withRequiredAuth from '@/utils/withRequiredAuth';
import { useAllOrganizations } from '@formbio/api';
import {
  AppHeader,
  AppHeaderTitle,
  Box,
  Divider,
  EmptyStateCard,
  LoadingPageProgress,
  RoundIconAvatar,
  ScrollableChild,
  ScrollableChildContainer,
  styled,
  Typography,
} from '@formbio/ui';
import { FilePlus as FilePlusIcon } from '@formbio/ui/components/Icons';
import { QueryParams } from '@formbio/utils';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const APP_ROOT_TITLE = 'Projects';

const BoxStyled = styled(Box)({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
});

const ScrollableChildStyled = styled(ScrollableChild)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
}));

const ScrollableChildContainerStyled = styled(ScrollableChildContainer)(
  ({ theme }) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundImage: `url(${bubbles.src})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: '100%',
    backgroundSize: '100%',
  }),
);

const BoxResponsiveHeightStyled = styled(Box)({
  flexGrow: 1,
  overflow: 'hidden',
});

const TypographyCenter = styled(Typography)({
  textAlign: 'center',
});

const IndexPage = () => {
  const router = useRouter();
  const { setPageTitle } = useNavigationContext();
  const { allOrganizations, isLoading: isLoadingOrganizations } =
    useAllOrganizations();
  // keep loading screen until redirects are done
  const [isRedirecting, setIsRedirecting] = useState(true);
  const { storedOrgId, storedProjectId, saveOrgId } =
    useOrgProjectLocalStorage();

  /**
   * Tries to redirect the user to project home
   * or organization page if localStorage has the params
   * the respective routes need.
   */

  useEffect(() => {
    if (storedOrgId && storedProjectId) {
      // take user to project home
      router.replace(
        getHref(router, navRoutes.projectHome, {
          [QueryParams.orgId]: storedOrgId,
          [QueryParams.pid]: storedProjectId,
        }),
      );
    } else if (storedOrgId) {
      // take user to organization home
      router.replace(
        getHref(router, navRoutes.organization, {
          [QueryParams.orgId]: storedOrgId,
        }),
      );
    }
  }, [storedOrgId, storedProjectId]);

  useEffect(() => {
    setPageTitle(APP_ROOT_TITLE);
  }, []);

  useEffect(() => {
    setIsRedirecting(true);
    if (!storedOrgId && allOrganizations?.length) {
      // take user to first organization home found
      saveOrgId(allOrganizations[0].id);
    }
    // user belongs to no orgs, need to show empty state
    if (!isLoadingOrganizations && !allOrganizations?.length) {
      setIsRedirecting(false);
    }
  }, [allOrganizations, isLoadingOrganizations]);

  return (
    <BoxStyled>
      <AppHeader
        backgroundColor='primary.main'
        sections={[
          {
            items: [<AppHeaderTitle key={0} title={APP_ROOT_TITLE} />],
          },
          {
            items: [<AppAvatarMenu key={0} />],
          },
        ]}
      >
        <Divider />
      </AppHeader>
      <BoxResponsiveHeightStyled // this box is responsible for setting the responsive height leftover from after the toolbar
        component='main'
      >
        <ScrollableChildContainerStyled>
          {isRedirecting ? (
            <LoadingPageProgress />
          ) : (
            <ScrollableChildStyled>
              <EmptyStateCard
                topRow={
                  <RoundIconAvatar>
                    <FilePlusIcon />
                  </RoundIconAvatar>
                }
                bottomRow={
                  <TypographyCenter variant='h4'>
                    You currently do not belong to any organization or project
                  </TypographyCenter>
                }
              />
            </ScrollableChildStyled>
          )}
        </ScrollableChildContainerStyled>
      </BoxResponsiveHeightStyled>
    </BoxStyled>
  );
};

export default withRequiredAuth(IndexPage);
